import React from "react";

import { Link, useParams } from "react-router-dom";
import getVideoId from "get-video-id";
// Bootstrap
import { Form, Stack, Row, Col } from "react-bootstrap";
import VimeoPlayer from "../VideoPlayer/Vimeo";
import moment from "moment";

export default function Lesson(props) {
  let pageData = props.localData.lesson;
  let { courseNumber, chapterNumber } = useParams();

  return (
    <li className="ts-lesson">
      <Row className="align-items-center">
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12} sm={6} className="text-center text-sm-start">
              <h5>
                <Link
                  className="text-gray-05 text-decoration-none"
                  to={
                    moment().isAfter(props.lesson.schedule) ||
                    props.lesson.schedule === null
                      ? `lesson-general/${props.lessonNumber}`
                      : `/course/${courseNumber}/chapter/${chapterNumber}`
                  }
                >
                  {props.rtlSwitch
                    ? props.lesson.name_ar
                    : props.lesson.name_en}
                </Link>
              </h5>
            </Col>
            <Col xs={6} sm={3} className="text-center">
              <p className="ts-desc-xs mb-0">
                {props.rtlSwitch
                  ? pageData.completed_ar
                  : pageData.completed_en}
              </p>
              <h6>{props.lesson.is_completed ? "100" : "0"}%</h6>
            </Col>
            <Col xs={6} sm={3} className="text-center">
              <p className="ts-desc-xs mb-0">
                {props.rtlSwitch ? pageData.duration_ar : pageData.duration_en}
              </p>
              <h6>
                {props.lesson.duration}
                &nbsp;
                {props.rtlSwitch ? "دقيقة" : "Min"}
              </h6>
            </Col>
          </Row>
          <p
            className="ts-desc-md "
            dangerouslySetInnerHTML={{
              __html: props.rtlSwitch
                ? props.lesson.description_ar
                : props.lesson.description_en,
            }}
          ></p>
        </Col>

        <Col xs={12} md={4}>
          {moment().isAfter(props.lesson.schedule) ||
          props.lesson.schedule === null ? (
            <Stack direction="horizontal" className="align-items-center gap-2">
              {props.lesson.type === "vimeo" ? (
                <VimeoPlayer Url={props.lesson.vimeo_url} />
              ) : (
                ""
              )}
              <Form>
                <div className="mb-3 ">
                  <Form.Check
                    role="button"
                    type="checkbox"
                    id={`default-checkbox`}
                    defaultChecked={props.lesson.is_completed}
                    onClick={props.toggleCompletion}
                    data-lesson-id={props.lesson.id}
                  />
                </div>
              </Form>
            </Stack>
          ) : (
            <div
              className="ts-schedule-thumbnail"
              style={{
                backgroundImage: `url(https://vumbnail.com/${
                  getVideoId(props.lesson.vimeo_url).id
                }_medium.jpg)`,
              }}
            >
              <div>
                <h6 className="text-center text-white mb-03">
                  {props.rtlSwitch
                    ? pageData.schedule_title_ar
                    : pageData.schedule_title_en}
                </h6>
                <h5 className="text-center text-white mb-0">
                  {props.lesson.schedule.split(" ")[0]}
                </h5>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </li>
  );
}
