import React, { useEffect } from "react";

// import { useNavigate } from "react-router-dom";

export default function Redirect() {
  // const navigate = useNavigate();
  // navigate("/home");
  // navigate("https://reham-wp.com/divag822/");

  useEffect(() => {
    window.location.replace("https://reham-wp.com/divag822/");
  }, []);

  return <div>Redirecting...</div>;
}
