import React, { useState, useEffect } from "react";
// react router
import { Link, useParams } from "react-router-dom";

// Api
import axios from "helpers/AxiosConfig";

// Bootstrap
import { Container, Button } from "react-bootstrap";
// Components
import ReviewsGroup from "components/Review/ReviewsGroup";
// React videoJS Player
import Vimeo from "components/VideoPlayer/Vimeo";
import VideoPlayer from "react-video-js-player";
// local data
import localData from "data/data.json";
let pageData = localData.get_started;

let videoUrl =
  "https://reham.mukkancom.dev/uploads/files/success_story/success_story_1662558234.mp4";

export default function GetStarted(props) {
  const [getStarted, setGetStarted] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState("");
  let { courseNumber } = useParams();

  useEffect(() => {
    getComments();
    getCourseDetails(courseNumber);
  }, [courseNumber]);

  // Fetching Course Details from Api
  const getCourseDetails = async (num) => {
    axios
      .get(`course_detail/${num}`)
      .then(function (response) {
        let getStartedArray = response.data.course.get_started_files;
        setGetStarted(getStartedArray);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  // Get all comments
  let getComments = () => {
    axios
      .get(`course_comments`, {
        params: { course_id: courseNumber },
      })
      .then(function (response) {
        let comments = response.data.comments;
        let commentsCount = response.data.total;
        setCommentsCount(commentsCount)
        setComments(comments);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Toggle like
  let ToggleLike = (commentId) => {
    axios
      .get(`like`, {
        params: { comment_id: commentId },
      })
      .then(function (response) {
        getComments(courseNumber);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });

    console.log("you've liked the comment id is : ", commentId);
  };

  return (
    <section className="ts-page-content">
      <Container>
        <h1 className="mb-06">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h1>
        {getStarted.map((item, index) => {
          return (
            <article key={index}>
              <h3 className="mb-05 fw-normal">
                {props.rtlSwitch ? item.title_ar : item.title_en}
              </h3>

              <p className="ts-desc-md mb-04  mb-11">
                {props.rtlSwitch ? item.description_ar : item.description_en}
              </p>

              <div className="video-wrapper mb-10">
                {item.type ? (
                  <Vimeo Title={item.title_ar} Url={item.file} />
                ) : (
                  <VideoPlayer controls={true} src={videoUrl} />
                )}
              </div>
            </article>
          );
        })}
        <div className="text-center mb-14">
          <Link
            className="text-gray-05 text-decoration-none"
            to={`/course/${courseNumber}/chapter/1`}
          >
            <Button
              variant="primary-04 btn--border-white "
              className="col-sm-5 col-xxl-4"
            >
              {props.rtlSwitch
                ? pageData.cta_button_ar
                : pageData.cta_button_en}
            </Button>
          </Link>
        </div>

        <ReviewsGroup
          rtlSwitch={props.rtlSwitch}
          comments={comments}
          commentsCount={commentsCount}
          courseId={courseNumber}
          ToggleLike={ToggleLike}
          isAdmin={props.isAdmin}
        />
      </Container>
    </section>
  );
}
