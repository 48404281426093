import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";

// Components
import ReviewsGroup from "components/Review/ReviewsGroup";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import VimeoPlayer from "components/VideoPlayer/Vimeo";
import TsBreadCrumb from "components/TsBreadCrumb/TsBreadCrumb";
import axios from "helpers/AxiosConfig";

export default function BonusMaterial(props) {
  let { courseNumber, bonusMaterialId } = useParams();

  const [bonusMaterial, setBonusMaterial] = useState([]);
  const [bonusMaterialList, setBonusMaterialList] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: "My programs", routeLink: "/my-programs" },
    { name: `Course ${courseNumber}`, routeLink: `/course/${courseNumber}` },
    {
      name: "Bonus Materials",
      routeLink: `/course/${courseNumber}/bonus-materials`,
    },
  ]);
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState("");

  useEffect(() => {
    updateBreadCrumbList();
    getBonusMaterials();
    // get comments on page load
    getComments(bonusMaterialId);
  }, [props.rtlSwitch]);

  const updateBreadCrumbList = () => {
    if (props.rtlSwitch) {
      setBreadCrumbList([
        { name: "برامجي", routeLink: "/my-programs" },
        {
          name: `${courseNumber}الدورة `,
          routeLink: `/course/${courseNumber}`,
        },
        {
          name: "مادة إضافية",
          routeLink: `/course/${courseNumber}/bonus-materials`,
        },
      ]);
    } else {
      setBreadCrumbList([
        { name: "My programs", routeLink: "/my-programs" },
        {
          name: `Course ${courseNumber}`,
          routeLink: `/course/${courseNumber}`,
        },
        {
          name: "Bonus Materials",
          routeLink: `/course/${courseNumber}/bonus-materials`,
        },
      ]);
    }
  };
  const getBonusMaterials = () => {
    axios
      .get("bonus_material", {
        params: {
          course_id: courseNumber,
        },
      })
      .then(function (response) {
        let materials = response.data.data;
        for (let material of materials) {
          if (material.id === parseInt(bonusMaterialId)) {
            setBonusMaterial(material);
            setBonusMaterialList(material.materials);
          }
        }
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Get all comments
  let getComments = (bonusId) => {
    axios
      .get(`bonus_material_comments`, {
        params: { bonus_material_id: bonusId },
      })
      .then(function (response) {
        let comments = response.data.comments;
        let commentsCount = response.data.total;
        setCommentsCount(commentsCount)
        setComments(comments);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Toggle like
  let ToggleLike = (commentId) => {
    axios
      .get(`like`, {
        params: { comment_id: commentId },
      })
      .then(function (response) {
        getComments(bonusMaterialId);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });

    console.log("you've liked the comment id is : ", commentId);
  };
  return (
    <Container className="py-5">
      <TsBreadCrumb ItemList={breadCrumbList} />

      <h3 className="mb-11">
        {props.rtlSwitch ? bonusMaterial.name_ar : bonusMaterial.name_en}
      </h3>
      {bonusMaterialList.map((bonusMaterialItem, index) => {
        return (
          <div key={index} className="mb-11">
            <p className="ts-desc-xs text-gray-05 mb-05">
              {props.rtlSwitch
                ? bonusMaterialItem.description_ar
                : bonusMaterialItem.description_en}
            </p>

            {bonusMaterialItem.type ? (
              <VimeoPlayer
                Url={bonusMaterialItem.file_url}
                addClassName="ts-rounded-sm overflow-hidden"
              />
            ) : (
              <VideoPlayer src={bonusMaterialItem.file_url} />
            )}
          </div>
        );
      })}

      <ReviewsGroup
        rtlSwitch={props.rtlSwitch}
        comments={comments}
        commentsCount={commentsCount}
        ToggleLike={ToggleLike}
        bonusMaterialId={bonusMaterialId}
        isAdmin={props.isAdmin}
      />
    </Container>
  );
}
