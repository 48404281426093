import "./profile.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import {
  Container,
  Stack,
  Row,
  Col,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Avatar from "react-avatar-edit";
// data
import localData from "data/data.json";
let pageData = localData.profile;

export default function Profile(props) {
  const [userImage, setUserImage] = useState(
    "/assets/icons/profile-picture.svg"
  );
  const [countryList, setCountryList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [countryId, setCountryId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [countryName, setCountryName] = useState("");
  // user info
  const [userInfo, setUserInfo] = useState({});
  // Bootstrap validation
  const [validated, setValidated] = useState(false);

  // bootstrap modal
  const [show, setShow] = useState(false);

  // image

  // const [src, setSrc] = useState();
  const [imageCrop, setImageCrop] = useState(false);
  const [storeImage, setStoreImage] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onCrop = (view) => {
    setImageCrop(view);
  };

  const onClose = (vew) => {
    setImageCrop(null);
  };

  const saveImage = () => {
    setStoreImage([...storeImage], { imageCrop });
    // const x = ([...storeImage], { imageCrop });
    console.log(imageCrop);
    handleClose();
    // onFileLoad();
  };

  // function onFileLoad(file) {
  //   console.log(file);
  // }

  // const profileImageShow = storeImage.map((item) => item.imageCrop);

  useEffect(() => {
    // getting user info from local storage and updating states
    const userInfoLocal = JSON.parse(localStorage.getItem("user-info"));
    setUserInfo(userInfoLocal);

    axios
      .get(`user_details`)
      .then(function (response) {
        let userDetails = response.data.data;

        setFirstName(userDetails.first_name);
        setLastName(userDetails.last_name);
        setDob(userDetails.dob);
        setGender(userDetails.gender);
        setCountryId(userDetails.country_id);
        setPhoneNumber(userDetails.mobile_no);
        setUserImage(userDetails.image_url);
        // Fetching countries list
        axios
          .get("countries", {})
          .then((response) => {
            const countries = response.data.countries;
            setCountryList(countries);
            setCountryName(countries[userDetails.country_id - 1].name); // setting country name
          })
          .catch(function (error) {
            console.log(error); // handle error
          });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  let UpdateUserDetails = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    const userId = userInfo.id;
    // updating use details
    axios
      .post("user_details", {
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        dob: dob,
        country_id: countryId,
        mobile_no: phoneNumber,
        user_id: userId,
        image: imageCrop,
      })
      .then((response) => {
        console.log(response.data);

        let success = response.data.success;
        let message = "successfully updated";
        let userDetails = response.data.data;
        if (success) {
          toast.success(message, {
            position: "bottom-right",
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // // update data
          // setFirstName(userDetails.first_name);
          // setLastName(userDetails.last_name);
          // setDob(userDetails.dob);
          // setGender(userDetails.gender);
          // setCountryId(userDetails.country_id);
          // setPhoneNumber(userDetails.mobile_no);
          // setUserImage(userDetails.image_url);
        }
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <div className="ts-profile">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container>
        <Row>
          {/* <h1>{userImage}</h1> */}
          <Col xxl={4} className="text-center mb-08 text-xxl-start mb-xxl-0 ">
            <div className="d-flex justify-content-center">
              <div className="ts-profile-pic mb-07" onClick={handleShow}>
                <img
                  width="353"
                  className="mw-100 "
                  // src={imageCrop}
                  // src={imageCrop ? imageCrop :  userImage}
                  src={
                    imageCrop ? imageCrop : "/assets/icons/profile-picture.svg"
                  }
                  // src={imageCrop ? imageCrop : userImage}
                  // src={profileImageShow.length ? profileImageShow : userImage}
                  alt="user profile"
                />
                {/* <input
                  id="ts-profile__image-upload"
                  className="ts-profile__image-upload"
                  type="file"
                  // onchange="previewFile()"
                  onChange={(e) => {
                    setUserImage(e.target.value); // return input value to parent
                  }}
                ></input> */}

                {/* const [imageCrop , setImageCrop] = useState(false); */}
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {/* <Modal.Header ></Modal.Header> */}
                {/* <closeButton /> */}
                <Modal.Title className="text-center my-3">
                  Upload image
                </Modal.Title>
                <Modal.Body>
                  <Avatar
                    width="353"
                    height="353"
                    name="Profile image"
                    onClose={onClose}
                    onCrop={onCrop}
                  />
                  <div className="text-center">
                    <Button
                      variant="primary-01 mt-4  mb-3 "
                      onClick={saveImage}
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <h2 className="text-gray-05 mb-02">{userInfo.name}</h2>
            <p className="heading-4 text-gray-05 text-break mb-02">
              {userInfo.email}
            </p>
          </Col>
          <Col xxl={8} className="d-flex align-items-center">
            <Form noValidate validated={validated}>
              <Stack className=" ts-form-item-group flex-row flex-wrap mb-08">
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.first_name_ar
                        : pageData.first_name_en}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      defaultValue={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value); // return input value to parent
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.last_name_ar
                        : pageData.last_name_en}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      defaultValue={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value); // return input value to parent
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="ts-form-item ">
                  <Form.Group controlId="dateOfBirth">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch ? pageData.dob_ar : pageData.dob_en}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder=" Date of birth"
                      defaultValue={dob}
                      onChange={(e) => {
                        setDob(e.target.value); // return input value to parent
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.gender_ar
                        : pageData.gender_en}
                    </Form.Label>

                    <Form.Select
                      aria-label="user gender"
                      onChange={(e) => {
                        setGender(e.target.value); // return input value to parent
                      }}
                      required
                    >
                      {(() => {
                        switch (gender) {
                          case "Male":
                            return (
                              <>
                                <option value={gender}>{gender}</option>
                                <option value="Female">Female</option>
                              </>
                            );
                          case "Female":
                            return (
                              <>
                                <option value={gender}>{gender}</option>
                                <option value="Male">Male</option>
                              </>
                            );

                          default:
                            return (
                              <>
                                <option>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </>
                            );
                        }
                      })()}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="ts-form-item ">
                  <Form.Group controlId="Country">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.country_ar
                        : pageData.country_en}
                    </Form.Label>
                    <Form.Select
                      aria-label="Country"
                      onChange={(e) => {
                        setCountryId(e.target.value);
                      }}
                    >
                      <option>{countryName}</option>

                      {countryList
                        ? countryList.map((coutry, index) => {
                            return (
                              <option key={index} value={coutry.id}>
                                {coutry.name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.phone_number_ar
                        : pageData.phone_number_en}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone number"
                      defaultValue={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value); // return input value to parent
                      }}
                    />
                  </Form.Group>
                </div>
              </Stack>
              <div className="text-center text-xxl-start">
                <Button
                  className="btn btn-primary-04 w-mob-100 "
                  type="submit"
                  onClick={UpdateUserDetails}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
