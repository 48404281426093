import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";

import WishListCard from "components/Courses/WishListCardsGroup";
import { Container } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.wishlist;

export default function WishList(props) {
  const [wishListCards, setWishListCard] = useState([]);

  useEffect(() => {
    getWishListData();
  }, []);

  let getWishListData = async () => {
    // Wishlist data
    axios
      .get("get_wishlist")
      .then(function (response) {
        setWishListCard(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  let removeCourseToWishList = async (courseId) => {
    console.log(courseId);
    axios
      .get("remove_from_wishlist", {
        params: {
          course_id: courseId,
        },
      })
      .then((response) => {
        getWishListData();
        let message = "Course removed from wishlist";
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <section className="ts-page-content">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <h3 className="mb-09">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h3>

        <button className="btn btn-primary-01 ts-rounded ts-rounded--not-top-right px-4 py-2 w-auto shadow border-white mb-10">
          {props.rtlSwitch ? pageData.button_ar : pageData.button_en}
        </button>
        <WishListCard
          wishListCards={wishListCards}
          rtlSwitch={props.rtlSwitch}
          removeCourseToWishList={removeCourseToWishList}
          addToCart={props.addToCart}
        />
      </Container>
    </section>
  );
}
