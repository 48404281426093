import React from "react";
import "./courses.scss";

import ProgramCard from "./ProgramCard";

export default function Programs(props) {
  return (
    <div>
      <h2 className="text-primary-04 mb-11">{props.CoursesTitle}</h2>
      <h1>{props.rtlSwitch}</h1>
      <div className="ts-courses-group">
        {props.ProgramsList.map((program, index) => {
          return (
            <ProgramCard
              key={index}
              Title={props.rtlSwitch ? program.name_ar : program.name_en}
              Description={
                props.rtlSwitch ? program.caption_ar : program.caption_en
              }
              IconUrl={program.thumb_image_url}
              Id={program.id}
              Btn={props.rtlSwitch ? "عرض" : "view"}
              isLogged={props.isLogged}
              // Btn="View"
            />
          );
        })}
      </div>
    </div>
  );
}
