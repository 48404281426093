import "./lesson-general.scss";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// Bootstrap
import { Container, Stack, Form, Row, Col } from "react-bootstrap";
// Components
import ReviewsGroup from "components/Review/ReviewsGroup";
// import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import TsBreadCrumb from "components/TsBreadCrumb/TsBreadCrumb";

import axios from "helpers/AxiosConfig";

import ReactAudioPlayer from "react-audio-player";
import VimeoPlayer from "components/VideoPlayer/Vimeo";

import moment from "moment";
import getVideoId from "get-video-id";
// data
import localData from "data/data.json";
let pageData = localData.lesson_general;

export default function LessonGeneral(props) {
  const [lesson, setLesson] = useState([]);
  const [currentLessonId, setCurrentLessonId] = useState();
  const [currentLessonNumber, setCurrentLessonNumber] = useState();
  const [nextLessonNumber, setNextLessonNumber] = useState();
  const [PreviousLessonNumber, setPreviousLessonNumber] = useState();
  const [lastLessonNumber, setLastLessonNumber] = useState();
  const [lessonVideoId, setLessonVideoId] = useState("");
  const [lessonScheduleDate, setLessonScheduleDate] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState("");
  const [hasQuiz, setHasQuiz] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: "My programs", routeLink: "/my-programs" },
    { name: "Lessons", routeLink: "/lessons" },
  ]);

  let { courseNumber, chapterNumber, lessonNumber } = useParams();
  useEffect(() => {
    setNextLessonNumber(parseInt(lessonNumber) + 1);
    setPreviousLessonNumber(lessonNumber - 1);
    let currentLessonId;
    for (let course of props.myCourses) {
      if (course.id === parseInt(courseNumber)) {
        for (let chapter of course.chapters) {
          if (chapter.id === parseInt(chapterNumber)) {
            console.log(chapter);
            setHasQuiz(chapter.has_quiz)
            let totalLessons = chapter.lessons.length;
            let currentLesson = chapter.lessons[lessonNumber - 1];

            currentLessonId = currentLesson.id;
            setCurrentLessonId(currentLessonId);
            setCurrentLessonNumber(lessonNumber);


            props.updateChapterId(chapter.id);
            setLesson(currentLesson);
            setLastLessonNumber(totalLessons);

            // console.log(totalLessons);
            // console.log(lessonNumber);
            setLessonVideoId(getVideoId(currentLesson.vimeo_url).id);
            if (currentLesson.schedule) {
              setLessonScheduleDate(currentLesson.schedule.split(" ")[0]);
            }

            if (props.rtlSwitch) {
              setBreadCrumbList([
                { name: "برامجي", routeLink: "/my-programs" },
                { name: course.name_ar, routeLink: `/course/${course.id}` },
                {
                  name: chapter.name_ar,
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
                {
                  name: "الدروس",
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
              ]);
            } else {
              setBreadCrumbList([
                { name: "My programs", routeLink: "/my-programs" },
                { name: course.name_en, routeLink: `/course/${course.id}` },
                {
                  name: chapter.name_en,
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
                {
                  name: "Lessons",
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
              ]);
            }
          }
        }
      }
    }

    getComments(currentLessonId);
  }, [courseNumber, chapterNumber, lessonNumber, props.myCourses]);

  // console.log(lessonNum);
  // Get all comments
  let getComments = (lessonNum) => {
    axios
      .get(`lesson_comments`, {
        params: { lesson_id: lessonNum },
      })
      .then(function (response) {
        let comments = response.data.comments;
        let commentsCount = response.data.total;
        setCommentsCount(commentsCount)
        setComments(comments);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Toggle like
  let ToggleLike = (commentId) => {
    axios
      .get(`like`, {
        params: { comment_id: commentId },
      })
      .then(function (response) {
        getComments(currentLessonId);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <section className="ts-page-content lesson-general">
      <Container>
        <TsBreadCrumb ItemList={breadCrumbList} />

        <h3 className="fw-normal">
          {props.rtlSwitch ? lesson.name_ar : lesson.name_en}
        </h3>
        <p
          className="ts-desc-md mb-06"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? lesson.description_ar
              : lesson.description_en,
          }}
        ></p>

        {moment().isAfter(lesson.schedule) || lesson.schedule === null ? (
          lesson.type === "vimeo" ? (
            <VimeoPlayer
              Url={lesson.vimeo_url}
              addClassName="mb-10 ts-rounded-sm overflow-hidden "
            />
          ) : (
            ""
          )
        ) : (
          <div
            className="ts-schedule-thumbnail mb-10"
            style={{
              backgroundImage: `url(https://vumbnail.com/${lessonVideoId}_medium.jpg)`,
            }}
          >
            <div>
              <h6 className="text-center text-white mb-03">
                {props.rtlSwitch
                  ? pageData.schedule_title_ar
                  : pageData.schedule_title_en}
              </h6>
              <h5 className="text-center text-white mb-0">
                {lessonScheduleDate}
              </h5>
            </div>
          </div>
        )}

        <Row className="align-items-center mb-09 ">
          <Col sm={6} lg={3} className="mb-05">
            <Link
              className={`btn btn-primary-07 shadow w-100 ${currentLessonNumber <= 1 ? "disabled ts-disabled" : ""
                }`}
              to={`/course/${courseNumber}/chapter/${chapterNumber}/lesson-general/${PreviousLessonNumber}`}
            >
              {props.rtlSwitch ? "الدرس السابق" : " Pervious Lesson"}
            </Link>
          </Col>
          <Col sm={6} lg={3} className="mb-05">
            <Link
              className={`btn btn-primary-07 shadow w-100 ${parseInt(lastLessonNumber) === parseInt(currentLessonNumber)
                ? "disabled ts-disabled"
                : ""
                }`}
              to={`/course/${courseNumber}/chapter/${chapterNumber}/lesson-general/${nextLessonNumber}`}
            >
              {props.rtlSwitch ? "الدرس التالي" : "Next Lesson"}
            </Link>
          </Col>

          {hasQuiz && parseInt(lastLessonNumber) === parseInt(currentLessonNumber) ?

            <Col sm={6} lg={3} className="mb-05">
              <Link
                className={`btn btn-primary-07 shadow w-100`}
                to={`/course/${courseNumber}/chapter/${chapterNumber}/quiz`}
              >
                {props.rtlSwitch ? "اختبار" : "Quiz"}
              </Link>
            </Col>
            : ""}
          {moment().isAfter(lesson.schedule) || lesson.schedule === null ? (
            lesson.related_link ? (
              <Col sm={6} lg={3} className="mb-05">
                {/* <Link
                 className="btn btn-primary-07 shadow w-100"
                 // to={`/${lesson.related_link}`}
                 to={{ pathname: lesson.related_link }}
               >
                 {props.rtlSwitch ? "روابط ذات علاقة" : "Related Links"}
               </Link> */}
                <a
                  className="btn btn-primary-07 shadow w-100"
                  href={lesson.related_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.rtlSwitch ? "روابط ذات علاقة" : "Related Links"}
                </a>
              </Col>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {moment().isAfter(lesson.schedule) || lesson.schedule === null ? (
            <Col sm={6} lg={3} className="mb-05">
              <Form.Check
                key={lesson.id}
                role="button"
                type="checkbox"
                id={lesson.id}
                label={props.rtlSwitch ? "وضع علامة مكتمل" : "Mark As Complete"}
                defaultChecked={lesson.is_completed}
                onClick={props.toggleCompletion}
                data-lesson-id={lesson.id}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
        <h1>
          {props.rtlSwitch
            ? pageData.course_description_ar
            : pageData.course_description_en}
        </h1>
        <p
          className="ts-desc-md mb-07"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? lesson.description_ar
              : lesson.description_en,
          }}
        ></p>

        {moment().isAfter(lesson.schedule) || lesson.schedule === null ? (
          <>
            {lesson.audio ? (
              <>
                <h1 className="mb-07">
                  {props.rtlSwitch
                    ? pageData.audio_file_ar
                    : pageData.audio_file_en}
                </h1>
                <ReactAudioPlayer
                  className="mb-10"
                  src={lesson.audio_url}
                  controls
                />
              </>
            ) : (
              ""
            )}

            <Stack
              direction="horizontal"
              className="align-items-center mb-11"
              gap="5"
            >
              {lesson.document ? (
                <a
                  className="ts-desc-md text-primary-07 text-decoration-none fw-bold"
                  href={lesson.document_url}
                  download
                >
                  {props.rtlSwitch ? "Download pdf" : "Download pdf"}
                </a>
              ) : (
                ""
              )}

              {lesson.audio ? (
                <a
                  className="ts-desc-md text-primary-07 text-decoration-none fw-bold"
                  href={lesson.audio_url}
                  download
                >
                  {props.rtlSwitch ? "Download .mp3" : "Download .mp3"}
                </a>
              ) : (
                ""
              )}
            </Stack>

            <ReviewsGroup
              rtlSwitch={props.rtlSwitch}
              comments={comments}
              commentsCount={commentsCount}
              ToggleLike={ToggleLike}
              lessonId={currentLessonId}
              isAdmin={props.isAdmin}
            />
          </>
        ) : (
          ""
        )}
      </Container>
    </section>
  );
}
