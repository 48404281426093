import "./buttons.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function ButtonGreen(props) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let userInfo = localStorage.getItem("is-logged") === "true";

    if (userInfo) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, []);

  const ProcessAddItemToCart = () => {
    if (isUserLoggedIn) {
      props.addToCartMultipleCourses(props.allCoursesInProgrammId);
    } else {
      StoreUserCoursesToLocalStorage();
      navigate("/register");
    }
  };
  const StoreUserCoursesToLocalStorage = () => {
    localStorage.setItem(
      "add_to_Cart_courses",
      JSON.stringify(props.allCoursesInProgrammId)
    );
  };
  return (
    <button
      className="btn btn-xl btn-docorated btn-docorated--green"
      onClick={ProcessAddItemToCart}
    >
      <span>{props.text}</span>
    </button>
  );
}
export function ButtonPrimary(props) {
  return (
    <button
      className="btn btn-xl btn-docorated btn-docorated--primary"
      >
      <span>{props.text}</span>
    </button>
  );
}
