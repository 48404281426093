import "./notification.scss";
import React from "react";

// Bootstrap
import { Container, Stack, Accordion, Button } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.notification;
export default function Notifications(props) {
  return (
    <section className="ts-notifications">
      <Container>
        <Stack
          direction="horizontal"
          className="justify-content-between align-items-center mb-07"
        >
          <h3 className="text-gray-03 mb-0 ">
            {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
          </h3>
          <Button variant="primary-07" className="rounded-pill">
            {props.rtlSwitch
              ? pageData.clear_all_button_ar
              : pageData.clear_all_button_en}
          </Button>
        </Stack>
        {props.NotificationsList.length > 0 ? (
          <Accordion defaultActiveKey={["1"]} flush>
            {props.NotificationsList.map((notification, index) => (
              <div key={index}>
                <p className="text-end ts-desc-sm fw-bold mb-01">
                  {notification.time}
                </p>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <span>{notification.message}</span>
                  </Accordion.Header>
                  <Accordion.Body>{notification.message}</Accordion.Body>
                </Accordion.Item>
              </div>
            ))}
          </Accordion>
        ) : (
          <h3 className="fw-normal mt-5 py-3">
            {props.rtlSwitch
              ? pageData.no_nofitification_ar
              : pageData.no_nofitification_en}
          </h3>
        )}
      </Container>
    </section>
  );
}
