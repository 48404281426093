import "./calender.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import { Modal, Button, Container } from "react-bootstrap";

// import ApiCalendar from "react-google-calendar-api";

// const config = {
//   clientId: "reham-368707",
//   apiKey: "AIzaSyBJ0rFdSDzCiSYOwKQxXk4GDV6DKa3UE8k",
//   scope: "https://www.googleapis.com/auth/calendar",
//   discoveryDocs: [
//     "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
//   ],
// };

// const apiCalendar = new ApiCalendar(config);

export default function TsCalendar(props) {
  const [events, setEvents] = useState([]);
  const [allEventsList, setAllEventsList] = useState([]);
  const [activeEventsModal, setActiveEventsModal] = useState([]);

  // ! temporary Start
  // useEffect(() => {
  //   console.log("component refresh");
  //   let allChangeBlocks = ["fc-prev-button", "fc-next-button"];
  //   for (let changeBlock of allChangeBlocks) {
  //     document
  //       .querySelector(`.${changeBlock}`)
  //       .addEventListener("click", () => {
  //         addClassToActiveDate(allEventsList);
  //       });
  //   }
  // });
  // ! temporary End

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    for (let event of allEventsList) {
      if (event.id === parseInt(id)) {
        setActiveEventsModal(event);
        console.log(event);
      }
    }
    setShow(true);
  };

  // const handleGoogleEvents = (event) => {
  //   apiCalendar.handleAuthClick();
  // };
  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    axios
      .get("events")
      .then(function (response) {
        let allEvents = [];
        let allEventApi = response.data.data;

        for (let event of allEventApi) {
          let image_url = event.image_url;
          let calenderEvent = {
            id: event.id,
            title: `${props.rtlSwitch ? event.title_ar : event.title_en},${
              props.rtlSwitch ? event.description_ar : event.description_en
            },${image_url}`,
            start: event.start_date,
            end: event.start_date,
            // url: event.link,
            // url: "www.youtube.com",
          };
          allEvents.push(calenderEvent);
        }

        setEvents(allEvents);
        setAllEventsList(allEventApi);
        addClassToActiveDate(allEvents);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  const addClassToActiveDate = (allEvents) => {
    for (let event of allEvents) {
      let dayBlock = document.querySelector(`[data-date="${event.start}"]`);
      if (!(dayBlock === null)) {
        dayBlock.classList.add("ts-active");
      }
    }
  };
  // const gotoPast = () => {
  //   FullCalendar.gotoDate("2000-01-01"); // call a method on the Calendar object
  // };

  const renderEventContent = (eventInfo) => {
    // console.log(eventInfo.event.id);
    return (
      <div
        className="ts-event-container"
        onClick={() => {
          handleShow(eventInfo.event.id);
        }}
        role="button"
      >
        <p className="mb-0 text-break">{eventInfo.event.title.split(",")[0]}</p>
        {/* <p className="mb-2 text-break">{eventInfo.event.title.split(",")[1]}</p> */}

        {/* <div className="text-center">
          <img
            className="ts-event-container__img mb-2"
            src={eventInfo.event.title.split(",")[2]}
            alt="..."
          />
        </div> */}
      </div>
    );
  };

  return (
    <section className="ts-calendar">
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <h6 className="mb-0">{activeEventsModal.start_date}</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              className="mw-100 mb-06"
              width="250"
              src={activeEventsModal.image_url}
              alt="..."
            />
          </div>
          <h4 className="text-center mb-04">
            {props.rtlSwitch
              ? activeEventsModal.title_ar
              : activeEventsModal.title_en}
          </h4>
          <p className="mb-06">
            {props.rtlSwitch
              ? activeEventsModal.description_ar
              : activeEventsModal.description_en}
          </p>
        </Modal.Body>
        {/* <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="w-auto rounded-pill px-4"
            variant="primary-01"
            onClick={handleGoogleEvents}
          >
            Add to Google calender
          </Button>
          <Button
            className="w-auto rounded-pill px-4"
            variant="primary-01"
            onClick={handleGoogleEvents}
          >
            Add to Apple calender
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Container className="mt-08 mb-10">
        <h2 className="mb-08">Calendar</h2>
        {/* <button onClick={gotoPast}>go to a date in the past</button> */}
        <div className="ts-full-calendar">
          <div className="ts-full-calendar__wrapper">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={events}
              eventContent={renderEventContent}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
