import "./cards.scss";
import React from "react";
import { Link } from "react-router-dom";

import Stack from "react-bootstrap/Stack";

// data
import localData from "data/data.json";
let pageData = localData.check_out_card;
export default function CheckOutCard(props) {
  return (
    <div className="ts-checkout-card  ts-rounded--not-top-right shadow">
      {/* <button
        className="btn border-0 p-0 ms-auto"
        onClick={() => props.removeFromCart(props.product.id)}
      >
        <img
          width="25"
          src="/assets/icons/Icon-material-delete-forever-primary.svg"
          alt="..."
        />
      </button> */}
      <div className="w-100 mt-auto">
        <Stack direction="horizontal" gap={3} className="mb-02">
          <h6 className="mb-0">
            {props.rtlSwitch ? props.product.name_ar : props.product.name_en}
          </h6>
          <Stack direction="horizontal" gap={1}>
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
          </Stack>
        </Stack>
        <p
          className="mb-04"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? props.product.description_ar
              : props.product.description_en,
          }}
        ></p>
        <Stack direction="horizontal" gap={3} className="mb-02">
          <h6>
            <del>{props.product.course_fees[0].fee}KD</del>
          </h6>
          <h6 variant="success" className="text-success">
            {props.product.course_fees[0].sale_fee}KD
          </h6>
        </Stack>
        {/* <Link
          className="ts-ceckout-card__btn btn btn-primary-07 btn--border-white w-100 fw-bold"
          to={"/course/" + props.product.id}
        >
          {props.rtlSwitch ? pageData.cta_button_ar : pageData.cta_button_en}
        </Link> */}
      </div>
    </div>
  );
}
