import React from "react";
import MyProgramCard from "./MyProgramCard";

export default function MyPrograms(props) {
  return (
    <div
      className={
        "ts-courses-group " +
        (props.groupCol === "2" ? "ts-courses-group--2-cards" : "")
      }
    >
      {props.myCourses.map((course, index) => {
        return (
          <MyProgramCard
            course={course}
            key={index}
            rtlSwitch={props.rtlSwitch}
          />
        );
      })}
    </div>
  );
}
